import { BrowserRouter, Switch, } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import Home from './components/Home';
import ScrollToTop from './components/lib/ScrollToTop';
import FirebaseAnalytics from './components/lib/FirebaseAnalytics';
import NotFound from './components/NotFound';
import Privacy from './components/Privacy';


function App() {
    return (
        <BrowserRouter>
            <FirebaseAnalytics />
            <ScrollToTop />
            
            <Switch>
                <AppLayout  exact path="/" component={Home} />
                <AppLayout  exact path="/privacy" component={Privacy} />

                <AppLayout  component={NotFound} status={404} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
