import Link2 from './lib/Link2';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const NotFound = (props) => {

    
    return (
        <Box sx={{ padding: 2 }}>
            <HelmetProvider>
                <Helmet>
                <meta
                        name="description"
                        content="ページが見つかりませんでした。collabo"
                    />
                    <title>Error 404(Not Found) | collabo</title>
                </Helmet>
            </HelmetProvider>

            <Box sx={{margin: '7% auto 0'}}>
                
                <Link2 href="/">
                    <img style={{ height: 40 }} src="/logo.svg" alt="collabo" />
                </Link2>
                
                <Typography variant="h3" component="div" gutterBottom my={3} sx={{color: '#666'}}>
                    404 not found 
                </Typography>

                <Typography variant="subtitle1" gutterBottom component="div">
                    ページが見つかりませんでした。
                </Typography>
                
                <Link2 href="/">戻る</Link2>
            </Box>
        </Box>
    );
};

export default NotFound;