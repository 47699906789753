
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import Typography from '@mui/material/Typography';
import classes from './Home.module.scss';
import { Player,  } from '@lottiefiles/react-lottie-player';
import { Box } from '@mui/material';
import Iframe from 'react-iframe'
import { Link } from 'react-router-dom'



const Home = (props) => {
    

    return (
        
        <Parallax pages={5} style={{ top: '0', left: '0' }} className={ classes.root }>

            <ParallaxLayer
                offset={0}
                speed={0.01}
                style={{zIndex: 1}}
                >
                <img className={classes.top_bg1} src="/react-assets/top-bg.svg" alt="bg" />
            </ParallaxLayer>


            <ParallaxLayer offset={ 1 } speed={0.2}>
                <img className={classes.top_bg2} src="/react-assets/top-bg.svg" alt="bg" />
            </ParallaxLayer>

            <ParallaxLayer offset={2} speed={0.1} >
                <img className={classes.top_bg3} src="/react-assets/top-bg.svg" alt="bg" />
            </ParallaxLayer>

            <ParallaxLayer offset={3} speed={0.4} >
                <img className={classes.top_bg4} src="/react-assets/top-bg.svg" alt="bg" />
            </ParallaxLayer>

            <ParallaxLayer offset={4} speed={0.5} >
                <img className={classes.top_bg5} src="/react-assets/top-bg.svg" alt="bg" />
            </ParallaxLayer>

            <ParallaxLayer
                offset={0}
                speed={0.3}
                style={{zIndex: 2}}
                >
                <Box sx={{
                    position: 'relative',
                }}>
                    <img style={{
                        position: 'absolute',
                        width: '45%',
                        right: 20,
                        top: 15,
                    }} src='/react-assets/logo.svg' alt="logo" />
                    <Box sx={{
                        position: 'absolute',
                        bottom: 30,
                        left: 30,
                        fontSize: 40,
                        color: '#fff',
                        fontWeight: 800,
                        textShadow: '0 3px 3px rgb(0 0 0 / 70%)',
                    }}>
                    ちょうどいいを<br />届ける<br />カンパニーで<br/>あり続ける
                    </Box>
                    <video style={{ height: '70%' }} playsInline loop muted autoPlay>
                            <source src="/react-assets/collabo_link_top (540p).mp4" type="video/mp4" />
                            Sorry, your browser doesn't support embedded videos.
                    </video>
                </Box>

                <Box sx={{p:3}}>
                    <Typography variant="h5" component="h3" sx={{ lineHeight: 2 }}>
                    ビジョンを掲げ、ITサービスを提供していきます。
                    </Typography>
                </Box>

            </ParallaxLayer>

            <ParallaxLayer
                offset={1}
                speed={0.3}
                style={{zIndex: 2}}
                >
                
                <Box sx={{p:3}}>
                    
                    <Typography variant="h4" component="h2" sx={{
                        fontWeight: "bold",
                        lineHeight: 1.5,
                        // fontSize: 3,
                        textAlign: 'center',
                    }}>
                        難しくない。
                    </Typography>
                    <Player
                        autoplay
                        loop
                        src="/react-assets/anim1.json"
                        style={{ width: '80%' }}
                    />

                    <Box sx={{
                        // font-size: 16px,
                        color: "#333",
                        lineHeight: 2.5,
                    }}>
                    ・使い方を覚える必要がない。<br />
                    ・運営コストがかからない。<br />
                    ・使い続ける事ができる。<br />
                    ・ユーザーに合わせてアップデートされる。<br />
                    ・サービスの目標とユーザーにもたらす<br />　メリットを明確に提示

                    </Box>
                </Box>


            </ParallaxLayer>


            <ParallaxLayer
                offset={2}
                speed={0.3}
                style={{zIndex: 2}}
                >

                    <Typography variant="h3" component="h1" sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        // fontSize: 3,
                    }}>
                        PRODUCTS
                    </Typography>
                    <Box sx={{p:8, pt:3, pb:2, }}>
                        <img style={{width:'100%'}} src="/react-assets/collabo-town.svg" alt="bg" />
                    </Box>

                    <Box sx={{
                        lineHeight: 2,
                        color: "#333",
                        p: 2,
                    }}>
                        コラボタウンはB2BでマッチングできるビジネスSNSです。私たちは企業単位より人単位で深く知りつながる事でビッグ・ビジネスが生まれると信じています。
                        <p style={{textAlign: 'center',}}>
                            <a style={{
                                textAlign: 'center',
                                color: '#2d99dc',
                            }}
                            href="https://collabo.town" target='_blank' rel="noreferrer">https://collabo.town</a>
                        </p>
                    </Box>
            </ParallaxLayer>


            

            <ParallaxLayer
                offset={3}
                speed={0.3}
                style={{zIndex: 2}}
                >

                    <Typography variant="h3" component="h1" sx={{
                        fontWeight: 500,
                        textAlign: 'center',
                        mb: 2,
                    }}>
                        NEWS
                    </Typography>

                    <Box sx={{
                        lineHeight: 2,
                        color: "#333",
                        p: 2,
                    }}>
                        02/15, 2022<br />
                        collabo.townをリリースしました。<br />
                        <br />
                        03/01, 2022<br />
                        株式会社Shapeplannningとご予算ドットコムを共同開発しました。
                        <br />
                        <br />

                    </Box>


                    <Box sx={{
                        lineHeight: 2,
                        color: "#333",
                        p: 2,
                        fontWeight: 800,
                    }}>
                        921-8163<br />
                        Isikawa kanazawa city<br />
                        yokogawa 7-26<br />
                        <br />

                        <Iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12821.907883336384!2d136.6248427!3d36.5426108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb34468a211f9496!2z44Kz44Op44Oc5qCq5byP5Lya56S-77yIY29sbGFibyBpbmMu77yJ!5e0!3m2!1sja!2sjp!4v1649665478100!5m2!1sja!2sjp"
                            allowFullScreen="" loading="eager"
                            width="100%" height="100%"
                            className={classes.iframe}
                        />
                    </Box>


                    
            </ParallaxLayer>



            <ParallaxLayer
                offset={4}
                speed={0.3}
                style={{zIndex: 2, backgroundColor: "#161E2E"}}
                >

                <Box
                    className={classes.footer}
                    sx={{
                        lineHeight: 2,
                        p: 4,
                        fontSize: 14,
                        color: "#fff",
                        textAlign: 'center',
                        height: '80%',
                    }}>
                        <a href="mailto:sales@collabo.link">お問い合わせ</a><br />
                        <Link to="/privacy">プライバシーポリシー</Link><br />
                        <br />
                        <Typography sx={{color: '#6E7D9C'}}>© 2021 collabo, Inc.</Typography>
                    </Box>
            </ParallaxLayer>

            


        </Parallax>
    );
};

export default Home;