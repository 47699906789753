import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//デバッグ表示
if(false)
{
    const div = document.createElement('div')
    div.style.position = 'fixed'
    div.style.left= div.style.right= div.style.top= div.style.bottom= 0
    div.style.margin = 'auto'
    div.style.zIndex= 1000000
    // div.style.opacity = 0.5
    div.style.pointerEvents = 'none'
    div.style.padding= '10px'
    div.style.overflow= 'scroll'
    div.style.backgroundColor = '#ffffffbb'
    document.body.appendChild(div)

    const _console_log = console.log

    const _log = (msg)=>{
        _console_log(msg)
        if(typeof(msg) != 'string')
            msg = JSON.stringify(msg, null, 4)
        div.innerHTML += msg + "<br />";
    }

    window.console = {
        log: _log,
        warn: _log,
        error: _log,
    }
}