import classes from './AppLayout.module.scss'
import { Route } from 'react-router-dom';
// import BottomMenu from './BottomMenu';



const AppLayout = ({ component: Component, props, ...rest }) => {
    

    return (
        <Route
            {...rest}
            render={(routeProps) => {
                
                return <>
                    <div id="app_root" className={ classes.root }>

                        <div className={ classes.box }>
                            <Component {...routeProps} {...props} />
                        </div>
                        {/* <BottomMenu /> */}
                    </div>
                    <div className={ classes.copylight }>© 2020-{new Date().getFullYear()} collabo Inc.</div>
                </>;

            }}
        />
    );
};

export default AppLayout;