
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { logEvent } from "./firebase";

const FirebaseAnalytics = () => {
    let location= useLocation();

    useEffect(()=> {
        
        logEvent('page_view');

    }, [location]);
    return null;
}



export default FirebaseAnalytics;
