import { useEffect } from 'react';
import { withRouter, useHistory, } from 'react-router-dom';

function ScrollToTop() {

    const history = useHistory()

    useEffect(() => {
        const unlisten = history.listen(() => {
            
            window.scrollTo(0, 0);
            var app_root_box = document.querySelector("#app_root_box")
            if(app_root_box)
                app_root_box.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return (null);
}

export default withRouter(ScrollToTop);