import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics as _getAnalytics, logEvent as _logEvent } from "firebase/analytics";
import { getStorage, connectStorageEmulator } from "firebase/storage";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};


export const firebaseApp = initializeApp(firebaseConfig);

//サーバーモードの時
if(process.env.REACT_APP_FIREBASE_MODE === 'server')
{
    initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA),
        isTokenAutoRefreshEnabled: true
    });
}

export const firebaseDB = getFirestore();

export const firebaseStorage = getStorage(firebaseApp);

//リージョンを分岐
const region = process.env.REACT_APP_FIREBASE_MODE === 'local' ? undefined : 'asia-northeast1'

export const firebaseFunctions = getFunctions(firebaseApp, region);

//ローカルモードの時
if(process.env.REACT_APP_FIREBASE_MODE === 'local')
{
    //エミュレーターに接続
    connectFirestoreEmulator(firebaseDB, 'localhost', 8080);
    connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);
    connectAuthEmulator(getAuth(), "http://localhost:9099");
    connectStorageEmulator(firebaseStorage, "localhost", 9199);
}

//ログイベント
const analytics = process.env.REACT_APP_FIREBASE_MODE === "local" ? null : _getAnalytics();
export const logEvent = (name, value) => {
    
    if(process.env.REACT_APP_FIREBASE_MODE === "local")
        return

    //スライド統計
    _logEvent(analytics, name, value);
}

